import {
  ANALYTICS_COLOR_1,
  ANALYTICS_COLOR_2,
  ANALYTICS_COLOR_3,
  ANALYTICS_COLOR_4,
  ANALYTICS_COLOR_5,
  ANALYTICS_COLOR_POSTS
} from '../../../config/constants';
import { displayFunctions } from '../../../utils/chartsUtils';

// ------------------------------------------------------------------------------------------------------
export const growth = {
  addAdditionalPlots: true,

  pin_clicks: {
    title: 'mixedChart.pin_clicks',
    hexColor: ANALYTICS_COLOR_1,
    type: 'line',

    display: displayFunctions.sum
  },
  followers: {
    title: 'mixedChart.followers',
    hexColor: ANALYTICS_COLOR_2,
    type: 'line',

    display: displayFunctions.lastValue
  },
  impressions: {
    title: 'mixedChart.impressions',
    hexColor: ANALYTICS_COLOR_3,
    type: 'line',

    display: displayFunctions.sum
  },
  page_visits: {
    title: 'mixedChart.page_visits',
    hexColor: ANALYTICS_COLOR_4,
    type: 'line',

    display: displayFunctions.sum
  }
};
export const engagement = {
  addAdditionalPlots: true,

  engagement_rate: {
    title: 'mixedChart.engagement_rate',
    hexColor: ANALYTICS_COLOR_1,
    type: 'line',

    display: displayFunctions.sum
  },
  engagement: {
    title: 'mixedChart.engagement',
    hexColor: ANALYTICS_COLOR_2,
    type: 'line',

    display: displayFunctions.sum
  }
};

export const closeup = {
  addAdditionalPlots: true,

  closeup: {
    title: 'mixedChart.closeup',
    hexColor: ANALYTICS_COLOR_1,
    type: 'line',

    display: displayFunctions.lastValue
  },
  closeup_rate: {
    title: 'mixedChart.closeup_rate',
    hexColor: ANALYTICS_COLOR_2,
    type: 'line',

    display: displayFunctions.lastValue
  }
};
export const outboundClick = {
  // addAdditionalPlots: true,
  outbound_click: {
    title: 'mixedChart.outbound_click',
    hexColor: ANALYTICS_COLOR_1,
    type: 'line',

    display: displayFunctions.sum
  },
  outbound_click_rate: {
    title: 'mixedChart.outbound_click_rate',
    hexColor: ANALYTICS_COLOR_2,
    type: 'line',

    display: displayFunctions.sum
  }
};
export const pinClick = {
  addAdditionalPlots: true,
  pin_click: {
    title: 'mixedChart.pin_click',
    hexColor: ANALYTICS_COLOR_1,
    type: 'line',

    display: displayFunctions.sum
  },
  pin_click_rate: {
    title: 'mixedChart.pin_click_rate',
    hexColor: ANALYTICS_COLOR_2,
    type: 'line',

    display: displayFunctions.sum
  }
};

export const save = {
  addAdditionalPlots: true,
  save: {
    title: 'mixedChart.save',
    hexColor: ANALYTICS_COLOR_1,
    type: 'line',

    display: displayFunctions.sum
  },
  save_rate: {
    title: 'mixedChart.save_rate',
    hexColor: ANALYTICS_COLOR_2,
    type: 'line',

    display: displayFunctions.sum
  }
};

export const video = {
  addAdditionalPlots: true,
  video_10s_view: {
    title: 'mixedChart.video_10s_view',
    hexColor: ANALYTICS_COLOR_1,
    type: 'line',

    display: displayFunctions.sum
  },
  video_avg_watch_time: {
    title: 'mixedChart.video_avg_watch_time',
    hexColor: ANALYTICS_COLOR_2,
    type: 'line',

    display: displayFunctions.sum
  },
  video_mrc_view: {
    title: 'mixedChart.video_mrc_view',
    hexColor: ANALYTICS_COLOR_5,
    type: 'line',

    display: displayFunctions.sum
  },
  video_v50_watch_time: {
    title: 'mixedChart.video_v50_watch_time',
    hexColor: ANALYTICS_COLOR_4,
    type: 'line',

    display: displayFunctions.sum
  },
  video_start: {
    title: 'mixedChart.video_start',
    hexColor: ANALYTICS_COLOR_3,
    type: 'line',

    display: displayFunctions.sum
  }
};

export const newConfigsMap: any = {
  growth,
  engagement,
  closeup,
  outbound_click: outboundClick,
  pin_click: pinClick,
  save,
  video
};
