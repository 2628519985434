/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import DatePicker from '../../../components/datePicker';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/esm/Container';
import { getLocationDetails } from '../../../redux/slices/location/locationDetails';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { newConfigsMap } from './config';
import IsLoadingIndicator from '../../../components/isLoadingIndicator';
import { DEFAULT_RANGE } from '../../../components/datePicker/utils';
import {
  getAnalyticsForLocation,
  transferData
} from '../../../redux/slices/analytics/plotsData';
import NewPlotTabs from '../../../components/plotTabs/newPlotTabs';
import { getAllDates } from '../../../utils/dateUtils';
import { PINTEREST } from '../../../config/constants';
import { data } from './demoDataDocument';
import { getUserDetails } from '../../../redux/slices/user/userDetails';
import { ANALYICS_FOR_SOCIAL_MEDIA } from '../../../config/firebase/collections';

const PinterestAnalyticsPage: React.FC = () => {
  const { isLoading, uuid, demo, platforms_information } = useSelector(
    (state: any) => state.locationDetails
  );
  console.log(platforms_information);
  const dispatch = useDispatch();
  const { locationslug } = useParams();
  const [error, setError] = useState(false);
  useEffect(() => {
    dispatch(getUserDetails() as unknown as AnyAction);
  }, [dispatch]);

  useEffect(() => {
    if (locationslug ?? '') {
      dispatch(getLocationDetails(locationslug) as unknown as AnyAction);
    }
  }, [dispatch, locationslug]);
  const [dates, setDates] = useState({
    ...DEFAULT_RANGE
  });

  const type = 'social_media_profile';
  const [plotsData, setPlotsData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (demo) {
      setPlotsData(data);
      setLoading(false);
      return;
    }
    if (!dates) return;
    // if(demo) {setPlotsData(transferData(demo, newConfigsMap)); return;}
    if (
      !isLoading &&
      uuid &&
      Object.keys(platforms_information).includes(PINTEREST)
    ) {
      setLoading(true);
      setError(false);
      getAnalyticsForLocation(
        ANALYICS_FOR_SOCIAL_MEDIA,
        type,
        platforms_information[PINTEREST][0].uuid,
        dates,
        PINTEREST
      )
        .then((res) => {
          setPlotsData(transferData(res, newConfigsMap));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
          setLoading(false);
        });
    }
  }, [uuid, isLoading, dates]);
  if (isLoading) return <IsLoadingIndicator />;

  return (
    <Container>
      <div className="d-flex flex-column flex-lg-row justify-content-lg-end">
        <DatePicker setDates={setDates} />
      </div>
      <div className="m-2" />
      <NewPlotTabs
        hasError={error}
        labelsLength={getAllDates(dates.startDate, dates.endDate).length}
        data={plotsData}
        config={newConfigsMap}
        display={true}
        isLoading={loading}
      />
    </Container>
  );
};
export default PinterestAnalyticsPage;
