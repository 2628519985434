import { displayFunctions } from '../../../utils/chartsUtils';

export const data = {
  plots: {
    growth: {
      // matches growth { pin_clicks, followers, impressions, page_visits }
      pin_clicks: {
        trend: 1,
        values: [50, 60, 45, 70, 80, 75, 90],
        // "pin_clicks" uses displayFunctions.sum in the config
        displayValue: displayFunctions.sum([50, 60, 45, 70, 80, 75, 90])
      },
      followers: {
        trend: 1,
        values: [150, 200, 220, 240, 270, 290, 320],
        // "followers" uses displayFunctions.lastValue in the config
        displayValue: displayFunctions.lastValue([
          150, 200, 220, 240, 270, 290, 320
        ])
      },
      impressions: {
        trend: 1,
        values: [100, 120, 130, 140, 150, 160, 170],
        // "impressions" uses displayFunctions.sum
        displayValue: displayFunctions.sum([100, 120, 130, 140, 150, 160, 170])
      },
      page_visits: {
        trend: 1,
        values: [20, 25, 30, 35, 40, 45, 50],
        // "page_visits" uses displayFunctions.sum
        displayValue: displayFunctions.sum([20, 25, 30, 35, 40, 45, 50])
      }
    },
    engagement: {
      // matches engagement { engagement_rate, engagement }
      engagement_rate: {
        trend: 1,
        values: [1.2, 1.4, 1.3, 1.6, 1.8, 1.7, 2.0],
        // config says displayFunctions.sum
        displayValue: displayFunctions.sum([1.2, 1.4, 1.3, 1.6, 1.8, 1.7, 2.0])
      },
      engagement: {
        trend: 1,
        values: [10, 15, 12, 18, 20, 22, 25],
        // config says displayFunctions.sum
        displayValue: displayFunctions.sum([10, 15, 12, 18, 20, 22, 25])
      }
    },
    closeup: {
      // matches closeup { closeup, closeup_rate }
      closeup: {
        trend: 1,
        values: [5, 7, 6, 9, 8, 10, 11],
        // config says displayFunctions.lastValue
        displayValue: displayFunctions.lastValue([5, 7, 6, 9, 8, 10, 11])
      },
      closeup_rate: {
        trend: 1,
        values: [1.0, 1.2, 1.1, 1.5, 1.2, 1.8, 2.0],
        // config says displayFunctions.lastValue
        displayValue: displayFunctions.lastValue([
          1.0, 1.2, 1.1, 1.5, 1.2, 1.8, 2.0
        ])
      }
    },
    outbound_click: {
      // matches outbound_click { outbound_click, outbound_click_rate }
      outbound_click: {
        trend: 1,
        values: [10, 9, 15, 13, 20, 18, 25],
        // config says displayFunctions.sum
        displayValue: displayFunctions.sum([10, 9, 15, 13, 20, 18, 25])
      },
      outbound_click_rate: {
        trend: 1,
        values: [0.5, 0.6, 0.55, 0.8, 0.7, 0.9, 1.0],
        // config says displayFunctions.sum
        displayValue: displayFunctions.sum([0.5, 0.6, 0.55, 0.8, 0.7, 0.9, 1.0])
      }
    },
    pin_click: {
      // matches pin_click { pin_click, pin_click_rate }
      pin_click: {
        trend: 1,
        values: [40, 50, 45, 60, 65, 70, 80],
        // config says displayFunctions.sum
        displayValue: displayFunctions.sum([40, 50, 45, 60, 65, 70, 80])
      },
      pin_click_rate: {
        trend: 1,
        values: [2, 3, 2.5, 4, 3, 3.5, 4.5],
        // config says displayFunctions.sum
        displayValue: displayFunctions.sum([2, 3, 2.5, 4, 3, 3.5, 4.5])
      }
    },
    save: {
      // matches save { save, save_rate }
      save: {
        trend: 1,
        values: [5, 7, 6, 9, 8, 10, 11],
        // config says displayFunctions.sum
        displayValue: displayFunctions.sum([5, 7, 6, 9, 8, 10, 11])
      },
      save_rate: {
        trend: 1,
        values: [0.5, 0.7, 0.6, 0.9, 0.8, 1.0, 1.1],
        // config says displayFunctions.sum
        displayValue: displayFunctions.sum([0.5, 0.7, 0.6, 0.9, 0.8, 1.0, 1.1])
      }
    },
    video: {
      // matches video {
      //   video_10s_view,
      //   video_avg_watch_time,
      //   video_mrc_view,
      //   video_v50_watch_time,
      //   video_start
      // }
      video_10s_view: {
        trend: 1,
        values: [10, 15, 12, 18, 22, 25, 30],
        displayValue: displayFunctions.sum([10, 15, 12, 18, 22, 25, 30])
      },
      video_avg_watch_time: {
        trend: 1,
        values: [5, 6, 5.5, 6.2, 5.8, 6.8, 7],
        displayValue: displayFunctions.sum([5, 6, 5.5, 6.2, 5.8, 6.8, 7])
      },
      video_mrc_view: {
        trend: 1,
        values: [50, 60, 55, 65, 70, 72, 80],
        displayValue: displayFunctions.sum([50, 60, 55, 65, 70, 72, 80])
      },
      video_v50_watch_time: {
        trend: 1,
        values: [100, 110, 105, 115, 120, 122, 130],
        displayValue: displayFunctions.sum([100, 110, 105, 115, 120, 122, 130])
      },
      video_start: {
        trend: 1,
        values: [15, 20, 18, 25, 30, 28, 35],
        displayValue: displayFunctions.sum([15, 20, 18, 25, 30, 28, 35])
      }
    }
  },
  additionalData: {
    // optional "additionalData", if desired
    posts_per_day: {
      trend: -1,
      values: [0, 0, 1, 0, 0, 2, 1],
      displayValue: 3
    }
  },
  dates: getLastSevenDays()
};

function getLastSevenDays(): string[] {
  const dates: string[] = [];
  for (let i = 1; i < 8; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    dates.push(date.toISOString().split('T')[0] + 'T00:00:00+00:00');
  }
  return dates.reverse();
}
